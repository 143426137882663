<template>
  <div id="home" class="view-home" @scroll="handleScroll">
    <!-- <MeanHeader /> -->
    <!-- <div style="height: 0.44rem; width: 100%"></div> -->
    <Swiper />
    <div class="home-notice-bar-wrap">
      <NoticeBar mode="" color="#C6C6C6" background="#010101" left-icon="volume-o" class="home-notice-bar" text="1.官方客服不会主动添加玩家联系方式，售卖充值卡或者说有其他充值渠道的都是骗子，充值请认准官方指定充值渠道，谨防上当受骗！
            2.合资，代充，代开盲盒之类要求让你转账的都是骗子，一定不能相信。
            3.本平台禁止未满18周岁的未成年人消费。
            4.平台玩家之间谨慎添加联系方式，谨防上当受骗！
            5.平台玩家之间禁止交易，禁止填写其他玩家的url，以免被骗，一但被骗则无法找回。
            6.切勿将自己账号借给他人，以免平台库存饰品丢失，谨防被骗！
            7.咨询和建议请添加TMSKINS平台官方微信群（右侧交流群）。
            8.盲盒开箱理性消费 最后祝各位玩家游戏愉快！
            9.近期网站内出现部分玩家恶意使用机器人进入roll房的情况，为了维护网站的公平公正公开，以及维护别的玩家们的利益，对此网站将封禁该类账号，同一ip地址下只可参与一次抽奖。
            " />
    </div>
    <!-- <div class="swiper-wrap">
      <van-swipe ref="swiper" :autoplay="5000" lazy-render @change="onChange">
        <van-swipe-item @click="goroll(item)" class="swiper-item" v-for="(item, index) in swiperList" :key="index">
          <img :src="item.image" style="width: 100%" />
        </van-swipe-item>
        <template #indicator>
          <div class="custom-indicator">
          </div>
        </template>
      </van-swipe>
    </div> -->
    <div v-if="false" class="playing-active">
      <div class="playing-wrap">
        <img style="height: 0.47rem" src="@/assets/images/mobile/m-home-title1.png" class="play-title" alt="" />
        <i class="triangle"></i>
        <div class="playing-box">
          <div class="play-item" v-for="(item, index) in playingList" :key="index" @click="gotoActiveChange(item)" @mouseover="playActiveChange(index)">
            <img :src="playingActive === index ? item.activeUrl : item.imgUrl" class="playing-item-img" alt="" />
            <div class="play-info">
              <img :src="playingActive === index ? item.titleActive : item.title" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-box">
      <div class="home-List-box">
        <div v-for="(item, index) in HotList" :key="index + 'a'">
          <div v-if="item.box.length" class="Title">
            <img :src="item.src" class="play-title" alt="" />
            <div class="triangle"></div>
          </div>
          <div v-if="item.box.length" class="home-List">
            <div class="home-list-item" v-for="(item, index) in item.box" :key="index" @click="ToOpen(item.id)">
              <div class="left-icon">
              </div>
              <div class="wrap_pic">
                <img v-lazy="item.cover" />
                <div class="weapon_cover">
                  <img :src="item.weapon_cover" alt="" />
                </div>
              </div>
              <div class="Blind_btn">
                <span>{{ item.name }}</span>
              </div>
              <div class="box-price">
                <Cprice :price="item.bean" />
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <Tabbar />
    <Rank :right='sidebarTransform' />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { GetHotBox, Rooms, Info } from "@/network/api.js";
import { playingList } from "./index.js";
import { NoticeBar } from "vant";
import MeanHeader from "@/components/common/MeanHeader.vue";
import Swiper from "@/components/common/NavHeader/Swiper.vue";
import Rank from "@/views/Rank/index.vue";
export default {
  components: {
    NoticeBar,
    MeanHeader,
    Swiper,
    Rank,
  },
  name: "Home",
  data() {
    return {
      HotList: [],
      BladeList: [],
      recruitList: [],
      classicList: [],
      vitalityList: [],
      hotboxlist: [],
      time: 10000,
      Headlines: [],
      soulList: [],
      XyList: [],
      swiperIndex: 0,
      swiperList: [],
      playingList: playingList,
      playingActive: null,
      // 侧边栏
      isScrolling: false,
      scrollTimer: null,
      sidebarTransform: ''
    };
  },

  created() {
    this.getBannerList();
    this.ObtainHotBox();
    this.GetRooms();
  },

  filters: {
    GuoLvOne(val) {
      if (val.length > 14) {
        return val.substr(0, 14) + "...";
      } else {
        return val;
      }
    },
    StyChoice(val) {
      if (val == "1") {
        // return 'border-bottom-color:#e6ba1c;background-image: linear-gradient(to top, rgba(230, 186, 28,.5), transparent)'
        return (
          "background: url(" +
          require("@/assets/images/Roll/back_huang.png") +
          ") no-repeat center bottom;background-size: contain;"
        );
      } else if (val == "2") {
        // return 'border-bottom-color:#d32ce6;background-image: linear-gradient(to top, rgba(211, 44, 230,.5), transparent)'
        return (
          "background: url(" +
          require("@/assets/images/Roll/back_fen.png") +
          ") no-repeat center bottom;background-size: contain;"
        );
      } else if (val == "3") {
        // return 'border-bottom-color:#834fff;background-image: linear-gradient(to top, rgba(131, 79, 255,.5), transparent)'
        return (
          "background: url(" +
          require("@/assets/images/Roll/back_lan.png") +
          ") no-repeat center bottom;background-size: contain;"
        );
      } else if (val == "4") {
        // return 'border-bottom-color:#4b69ff;background-image: linear-gradient(to top, rgba(75, 105, 255,.5), transparent)'
        return (
          "background: url(" +
          require("@/assets/images/Roll/back_hui.png") +
          ") no-repeat center bottom;background-size: contain;"
        );
      } else {
        // return 'border-bottom-color:#bbbbbb;background-image: linear-gradient(to top, rgba(187, 187, 187,.5), transparent)'
        return (
          "background: url(" +
          require("@/assets/images/Roll/back_hui.png") +
          ") no-repeat center bottom;background-size: contain;"
        );
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isScrolling = true;
      this.sidebarTransform = '-0.25rem';
      clearTimeout(this.scrollTimer);
      this.scrollTimer = setTimeout(() => {
        this.isScrolling = false;
        this.sidebarTransform = '';
      }, 500); // 设置一个延时，判断用户停止滚动
    },
    getBannerList() {
      Info().then((res) => {
        // console.log(res.data.data.banners, 'banners');
        this.swiperList = res.data.data.banners;
        this.swiperList = this.swiperList.map(item => {
          return {
            ...item,
            href: item?.href?.replace('tmskins', 'm.tmskins')
          };
        });
        // this.swiperList = [
        //   {
        //     href: "https://m.tmskins.com",
        //     image: require("../../assets/images/mobile/m-banner-0.png"),
        //     title: "banner0"
        //   },
        //   {
        //     href: "https://m.tmskins.com/Roll",
        //     image: require("../../assets/images/mobile/m-banner-1.png"),
        //     title: "banner1"
        //   },
        //   {
        //     href: "https://m.tmskins.com/shareReward",
        //     image: require("../../assets/images/mobile/m-banner-2.png"),
        //     title: "banner2"
        //   },
        //   {
        //     href: "https://m.tmskins.com/shareReward",
        //     image: require("../../assets/images/mobile/m-banner-3.png"),
        //     title: "banner3"
        //   },
        // ];
        // this.swiperList = [
        //   { image: 'https://api.acekeys.net/uploads/20240129/04908e4e89fda46c6ce2b775f4a2ef5e.png' },
        //   { image: 'https://api.acekeys.net/uploads/20240129/1ebfbd25566a06476026da19d9ef3abb.png' },
        //   { image: 'https://api.acekeys.net/uploads/20240129/4347103b1d1fdcffb249eb3992aa9e13.png' }
        // ];
      });
    },
    // 写死跳转roll房
    goroll(item) {
      if (!this.user.id) {
        this.$message.error("请先登录");
        return false;
      }
      location.href = item.href;
      // this.$router.push('/Roll');
    },
    gotoActiveChange(item) {
      this.$router.push(item.page);
    },
    playActiveChange(index) {
      this.playingActive = index;
    },
    onChange(index) {
      this.swiperIndex = index;
    },
    onChange1(index) {
      this.$refs.swiper.swipeTo(index);
    },
    //热门宝箱
    ObtainHotBox() {
      GetHotBox().then((res) => {
        this.HotList = res.data.data;
      });
    },
    //开箱
    ToOpen(id) {
      this.$router.push({
        path: "/OpeningBox",
        query: {
          id: id,
        },
      });
    },

    //房间
    GetRooms() {
      Rooms("1").then((res) => {
        let RoomList = res.data.data.data;
        // function compare(property) {
        // 	return function(a, b) {
        // 		let value1 = Number(a[property]);
        // 		let value2 = Number(b[property]);
        // 		return value1 - value2
        // 	}
        // }
        let haveHand = RoomList.filter((item) => {
          return item.status_alias == "进行中";
        });
        this.Headlines = haveHand.slice(0, 3);
      });
    },

    //前往房间
    toWelfareInfo(id) {
      this.$router.push({
        path: "/room",
        query: {
          id: id,
        },
      });
    },

    ...mapMutations([
      "User",
      "GetIndividualsShow",
      "GetRegisterShow",
      "GetSignInShow",
    ]),
  },

  computed: {
    ...mapState(["user", "IndividualsShow", "RegisterShow", "SignInShow"]),
  },
};
</script>
<style lang="scss">
.view-home {
  .home-notice-bar-wrap {
    padding: 0 0.2rem;
    .van-notice-bar {
      height: 0.24rem;
      line-height: 0.24rem;
      border-radius: 0.24rem;
    }
  }
  .home-notice-bar {
    font-size: 0.12rem;
    border: 0.01rem solid #1d2b50;
    border-radius: 0.1rem;
    .van-notice-bar__left-icon {
      color: #e5623a;
    }
  }
  .swiper-wrap {
    width: 140vw;
    position: relative;
    left: -20vw;
    .swiper {
      .van-swipe__track {
        .swiper-item {
          width: 100%;
          position: relative;
          cursor: pointer !important;
        }
      }
    }
  }

  .triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.05rem 0.05rem 0.05rem 0.05rem;
    border-color: #e9e9e9 transparent transparent transparent;
    margin: 0.05rem auto;
  }

  .custom-indicator {
    position: absolute;
    bottom: 0.2rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    cursor: pointer;

    i {
      display: block;
      width: 0.25rem;
      height: 0.25rem;
      border-radius: 50%;
      background: #575e60;
      margin-right: 0.1rem;
    }

    .active-swiper {
      background: #d87b28;
    }
  }

  .playing-active {
    width: 100%;

    .playing-wrap {
      // width: 3.4rem;
      padding-top: 0.3rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .playing-box {
        display: flex;

        .play-item {
          position: relative;
          cursor: pointer;

          // .playing-item-img {
          //   width: 0.8rem;
          //   // height:1rem;
          // }
          &:nth-child(1) {
            .playing-item-img {
              width: 1rem;
            }
          }

          &:nth-child(2) {
            .playing-item-img {
              width: 1rem;
            }
          }

          &:nth-child(3) {
            .playing-item-img {
              width: 1rem;
            }
          }

          &:nth-child(4) {
            .playing-item-img {
              width: 1rem;
            }
          }

          .play-info {
            position: absolute;
            bottom: 35%;
            left: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            transform: translateX(-35%);

            span {
              font-size: 0.08rem;
            }

            img {
              height: 0.12rem;
              max-width: 100px;
            }
          }

          .play-info:nth-of-type(4) {
            // margin-top: 10px;
          }

          &:nth-child(1) {
            transform: translate(18%, 4%);

            .play-info {
              transform: translate(-30%, -101%);
            }
          }

          &:nth-child(2) {
            transform: translate(13%, 0%);

            .play-info {
              transform: translate(-60%, 10%);
            }
          }

          &:nth-child(3) {
            transform: translate(-5%, 0%);

            .play-info {
              transform: translate(-60%, 40%);
            }
          }

          &:nth-child(4) {
            .play-info {
              transform: translate(-85%, -278%);
            }

            transform: translate(-14%, 2%);
          }
        }
      }
    }
  }

  .home-box {
    width: 100%;
    margin-top: 0.2rem;
    .home-List-box {
      .Title {
        width: 100%;
        // height:0.3rem;
        // margin-top: 0.4rem;
        // height: 1rem;
        // margin-left: -1.6rem;
        // padding: 0.1rem;
        // margin-bottom: 0.4rem;

        .play-title {
          // width: 3.4rem;
          height: 0.35rem;
          margin: 0 auto;
        }
      }

      .home-List {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 0 0.135rem;

        .home-list-item {
          width: 33.33%;
          position: relative;
          box-sizing: border-box;
          margin-bottom: 0.14rem;
          .left-icon {
            position: absolute;
            top: 0rem;
            left: 0rem;
          }

          .wrap_pic {
            // height: 1.5rem;
            height: 0.94rem;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            img {
              height: 100%;
            }

            .weapon_cover {
              position: absolute;
              height: 0.6rem;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                height: 100%;
              }
            }
          }

          .Blind_btn {
            text-align: center;
            margin-bottom: 0.08rem;
            color: #cecece;
            font-size: 0.12rem;
            margin-top: -0.1rem;
          }
          .box-price {
          }
        }
      }
    }
  }

  @keyframes zhuan {
    to {
      transform: rotate(1turn);
    }
  }
}
</style>
