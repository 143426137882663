import swiper1 from "@/assets/images/home/home-banner-swiper1.png";
import playing1 from "@/assets/images/home/playing/playing-1.png";
import playing2 from "@/assets/images/home/playing/playing-2.png";
import playing3 from "@/assets/images/home/playing/playing-3.png";
import playing4 from "@/assets/images/home/playing/playing-4.png";
import playingActive1 from "@/assets/images/home/playing/playing-1-active.png";
import playingActive2 from "@/assets/images/home/playing/playing-2-active.png";
import playingActive3 from "@/assets/images/home/playing/playing-3-active.png";
import playingActive4 from "@/assets/images/home/playing/playing-4-active.png";
import playingInfo1 from "@/assets/images/home/playing/playing-info1.png";
import playingInfo2 from "@/assets/images/home/playing/playing-info2.png";
import playingInfo3 from "@/assets/images/home/playing/playing-info3.png";
import playingInfo4 from "@/assets/images/home/playing/playing-info4.png";
import playingInfoActive1 from "@/assets/images/home/playing/playing-info-active-1.png";
import playingInfoActive2 from "@/assets/images/home/playing/playing-info-active-2.png";
import playingInfoActive3 from "@/assets/images/home/playing/playing-info-active-3.png";
import playingInfoActive4 from "@/assets/images/home/playing/playing-info-active-4.png";
export const swiperList = [swiper1];
export const playingList = [
  {
    imgUrl: playing1,
    activeUrl: playingActive1,
    title: playingInfo1,
    titleActive: playingInfoActive1,
    info: "真实高爆",
    page: "/classicsBox",
  },
  {
    imgUrl: playing2,
    activeUrl: playingActive2,
    title: playingInfo2,
    titleActive: playingInfoActive2,
    info: "概率最高75%",
    page: "/Lucky",
  },
  {
    imgUrl: playing3,
    activeUrl: playingActive3,
    title: playingInfo3,
    titleActive: playingInfoActive3,
    info: "你来我就送",
    page: "/Roll",
  },
  {
    imgUrl: playing4,
    activeUrl: playingActive4,
    title: playingInfo4,
    titleActive: playingInfoActive4,
    info: "急速发货",
    page: "/Mall",
  },
];
